<template>
  <div>
    <div class='container-fluid px-0 view-home-maquillate'>
      <div class='details-covers'>
        <div class='px-3 my-5 info-box1'>
          <div class='view-home-header__info'>
            <h1 style='font-weight: 900'>
              1RA Y ÚNICA PLATAFORMA DE AUTOMAQUILLAJE
            </h1>
            <p class='mt-3 mb-4'>
              Vence obstáculos, luce bella y siéntete segura mediante el
              maquillaje
            </p>
            <div
              class='row justify-content-center view-home-maquillate__header'
            >
              <router-link to='/explorar'>
                <button
                  class='btn mr-3 mt-2 button-see-classes'
                  style='font-weight: bold'
                  type='submit'
                >
                  VER CLASES
                </button>
              </router-link>
              <router-link v-if='!getToken' to='/register'>
                <button
                  class='btn btn-two pulse-animation mr-3 mt-2'
                  style='border: 1.2px solid #b7227e'
                  type='submit'
                >
                  CREAR CUENTA GRATIS
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class='height-box'>
          <img class='img-box1' src='https://devmaquillate.b-cdn.net/home/img.webp' />
        </div>
      </div>
    </div>

    <div class='backg-img2 container-fluid text-center pt-5'>
      <div class='container-fluid size px-0'>
        <div class='row'>
          <div class='col-12'>
            <p class='title-h2 mb-4'>
              MÁS DE 100 CLASES PARA APRENDER EN DISTINTAS CATEGORÍAS
            </p>
          </div>

          <div class='col-12 view-home-tabs pl-0 view-tabs-mobile mx-0 pl-lg-3 pr-0'>
            <span class='sidebar-home-hand-left'> </span>
            <md-tabs
              v-if='getHomeClasses'
              class='md-transparent'
              md-alignment='fixed'
            >
              <md-tab md-label='Todas las clases' class='px-0'>
                <carousel
                  :navigation-enabled='true'
                  :per-page='1'
                  :per-page-custom='[
                    [545, 2],
                    [825, 3],
                    [1200, 4],
                  ]'
                  :scroll-per-page='true'
                  :speed='500'
                  navigation-next-label="<i class='fas fa-chevron-right'></i>"
                  navigation-prev-label="<i class='fas fa-chevron-left'></i>"
                >
                  <slide
                    v-for='_class in getHomeClasses.items'
                    :key='_class.id'
                    class='d-flex flex-column flex-card-display align-courses-center pl-2'
                    style='flex-basis: inherit;'
                  >
                    <card-class
                      class='card-course-width mx-auto mx-lg-1'
                      :data='_class'
                    />
                  </slide>
                </carousel>
              </md-tab>

              <md-tab
                v-for='category in this.getPublicCategories'
                :id='category.title'
                :key='category.id'
                :md-label='category.title'
              >
                <carousel
                  :navigation-enabled='true'
                  :per-page='1'
                  :per-page-custom='[
                  [545, 2],
                  [825, 3],
                  [1200, 4],
                ]'
                  :scroll-per-page='true'
                  :speed='500'
                  navigation-next-label="<i class='fas fa-chevron-right'></i>"
                  navigation-prev-label="<i class='fas fa-chevron-left'></i>"
                >
                  <slide
                    v-for='_class in getClassByCategories(
                      getHomeClasses.items,
                      category.id,
                    )'
                    :key='_class.id'
                    class='d-flex flex-column flex-card-display align-courses-center pl-2'
                    style='flex-basis: inherit;'

                  >
                    <card-class
                      class='card-course-width mx-auto mx-lg-1'
                      :data='_class'
                    />
                    <div
                      v-if='
                        !getClassByCategories(getHomeClasses.items, category.id)
                          .length
                      '
                      class='col-12'
                    >
                      <h3
                        class='details-class-description'
                        style='color: white'
                      >
                        NO SE HAN REGISTRADO CURSOS EN ESTA CATEGORIA
                      </h3>
                    </div>
                  </slide>
                </carousel>
              </md-tab>
              <!-- ********************* FIN DE CARRUSEL ******************************** -->
            </md-tabs>
          </div>
          <div class='col-12 text-center mx-0 px-0'>
            <router-link style='text-decoration: none' to='/explorar/clases'>
              <button class='button-view-all-courses my-4 text-center'>
                VER TODAS LAS CLASES
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class='container-fluid view-home__part3' style='background: white'>
      <div class='container size'>
        <div class='row'>
          <div
            class='col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 view-home__part3__column-left pt-0 pb-4'
          >
            <p class='title-h2'>EDUCACIÓN EN LA PUNTA DE TUS DEDOS</p>
            <p>
              Aprende de cualquier parte del mundo, sin horarios y a tu ritmo.
              Controla tu tiempo y continúa educandote cuando quieras.
            </p>
          </div>
          <div
            class='col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-0 view-home__part3__column-right'
          >
            <img src='https://devmaquillate.b-cdn.net/home/educacion.png' alt='educacion' class='h-w-education-home-banner'>
          </div>
        </div>
      </div>
    </div>
    <div class='container-fluid view-home__part8'>
      <div class='row'>
        <div class='col-6 view-home__part8__column-left'>
          <h1>AUTOMAQUÍLLATE</h1>
        </div>
        <div class='col-6 view-home__part8__column-right'>
          <h1>COMO UNA PRO</h1>
        </div>
      </div>
    </div>
    <div class='container-fluid view-home__part3' style='background: white'>
      <div class='container size'>
        <div class='row'>
          <div
            class='col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-0 view-home__part3__column-right'
          >
            <img src='https://devmaquillate.b-cdn.net/home/comunidad.png' alt='comunidad' class='h-w-education-home-banner'>

          </div>
          <div
            class='col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 view-home__part3__column-left'
          >
            <p class='title-h2'>ÚNETE A UNA COMUNIDAD GLOBAL</p>
            <p>
              Miles de personas de todo el mundo aprenden día a día sobre el
              mundo del Maquillaje. ¡Únete hoy!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class='view-home__part4'>
      <div class='container'>
        <div class='row'>
          <div class='col-12'>
            <p class='pb-3 title-h2'>¡ENCUENTRA LO QUE TE GUSTA!</p>
            <p>
              Ya sea que seas un principiante o quieras intensificar tu
              aprendizaje, obtén la experiencia completa en casa con cientos de
              clases para todo los estilos y gustos.
            </p>
          </div>

          <div class='col-12 px-0 buttons-box'>
            <router-link style='text-decoration: none' to='/explorar'>
              <button class='btn btn-primary btn-icons' type='submit'>
                <div class='icon-box'>
                  <img
                    alt='img_general'
                    class='size-icon'
                    src='/img/theme/youtube.png'
                  />
                </div>
                <div class='info-button pl-4 text-left boxTotal'>
                  <span class='info-title'>Total</span>
                  <span class='class-info'
                  >{{getHomeClasses.total }} Clases</span>
                </div>
              </button>
            </router-link>

            <!-- **********BOTONES DINAMICOS****** -->
            <div v-for='category in getPublicCategories' :key='category.id'>
              <router-link
                :to='`/explorar/clases/categoria/${category.slug}`'
                style='text-decoration: none'
              >
                <button class='btn btn-primary btn-icons' type='submit'>
                  <div class='icon-box'>
                    <img
                      :src='
                        category.course_category_icon_file &&
                        category.course_category_icon_file.url
                          ? category.course_category_icon_file.url
                          : `img/theme/youtube.png`
                      '
                      alt=''
                      class='size-icon'
                    />
                  </div>
                  <div class='info-button pl-4 text-left boxTotal'>
                    <span class='info-title'>{{ category.title }}</span>
                    <span class='class-info'
                    >{{ category.classes_count }}
                      Clases</span
                    >
                  </div>
                </button>
              </router-link>
            </div>
          </div>
          <div class='col-12'>
            <router-link style='text-decoration: none' to='/explorar/clases'>
              <button class='button-all-classes-home text-center'>
                VER TODAS LAS CLASES
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class='container-fluid view-home__part3' style='background: white'>
      <div class='container size'>
        <div class='row'>
          <div
            class='col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-0 view-home__part3__column-right pl-0 pr-4'
          >
            <img src='https://devmaquillate.b-cdn.net/home/servirte-section.png' alt='servirte' class='h-w-education-home-banner'>

          </div>
          <div
            class='col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 view-home__part3__column-left pt-0'
          >
            <p class='title-h2 pt-4'>¡ESTAMOS PARA SERVIRTE!</p>
            <p>
              Recibe asistencia en menos de 24 horas de nuestro equipo en
              Maquillate.com para resolver o responder cualquier pregunta que
              tengas. Servirte es nuestra misión.
            </p>
            <router-link
              style='text-decoration: none'
              to='/contactanos'
              class='text-center text-lg-left mx-0 px-0'
            >
              <button class='button-contact my-4'>Contáctanos</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class='backg-img2 text-center pt-5 '>
      <div class='container-fluid size px-0'>
        <div class='row'>
          <div class='col-12 mb-0 pb-0'>
            <p class='title-h2 text-center'>
              CURSOS PARA AGILIZAR TU EDUCACIÓN
            </p>
          </div>
          <div
            class='col-12 view-home-tabs view-tabs-mobile courses-payments mt-0 pt-0 mx-0 pl-lg-3 pr-lg-0'
          >
            <md-tabs
              v-if='getPublicCourses'
              class='md-transparent'
              md-alignment='fixed'
            >
              <md-tab class='px-2'>
                <carousel
                  :navigation-enabled='true'
                  :per-page='1'
                  :per-page-custom='[
                    [545, 2],
                    [825, 3],
                    [1200, 4],
                  ]'
                  :scroll-per-page='true'
                  :speed='500'
                  :class="{'courses-home-carousel': getPublicCourses.items.length < 4}"
                  navigation-next-label="<i class='fas fa-chevron-right'></i>"
                  navigation-prev-label="<i class='fas fa-chevron-left'></i>"
                >
                  <slide
                    v-for='course in getPublicCourses.items'
                    :key='course.id'
                    class='d-flex flex-column flex-card-display align-courses-center'
                    style='flex-basis: inherit; padding-left: 10px; '
                  >
                    <card-course
                      class='card-course-width mx-auto mx-lg-1'
                      :data='course'
                    />
                  </slide>
                </carousel>
              </md-tab>
            </md-tabs>
          </div>
          <div class='col-12'>
            <router-link style='text-decoration: none' to='/explorar/cursos'>
              <button
                class='button-all-classes-home text-center my-4 mx-0 px-0'
              >
                VER TODOS LOS CURSOS
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class='view-home__part5'>
      <div class='container-fluid size-instructor'>
        <div class='row'>
          <div class='col-12 mb-5'>
            <h2 class='pb-3'>
              CLASES IMPARTIDAS POR MAQUILLISTAS PROFESIONALES
            </h2>
            <p>
              Los instructores de Maquíllate.com, son iconos y expertos de la industria de maquillaje, emocionadas de
              compartir su experiencia, sabiduría y herramientas contigo.
            </p>
            <router-link style='text-decoration: none' to='/instructores'>
              <button class='btn button-view-all-instructors-home my-4'>
                VER INSTRUCTORES
              </button>
            </router-link>
          </div>

          <div
            class='col-12 mt-4 cards-instructors text-center align-content-center align-items-center pr-2 pl-2'
          >
            <div class='row mx-auto text-center carousel-instructor-desktop'>
              <div
                v-for='instructor in this.getPublicInstructors'
                :key='instructor.id'
                class='col-12 col-lg-3 align-items-center mx-auto mb-4'
              >
                <router-link
                  :to='`/instructores/instructor/${
                    instructor.id
                  }/${instructor.firstname.toLowerCase()}-${instructor.lastname.toLowerCase()}`'
                  style='text-decoration: none'
                >
                  <div
                    :style="{
                      backgroundImage: `url('${
                        instructor.avatar && instructor.avatar.url
                          ? instructor.avatar.url
                          : '@/assets/images/logo/default-img-instructors.png'
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '240px',
                      maxHeight: '240px',
                      width: '240px',
                    }"
                    class='text-center mx-auto instructor-carousel-image'
                  ></div>
                  <h4 class='text-uppercase'>
                    {{ instructor.firstname }} {{ instructor.lastname }}
                  </h4>
                </router-link>
                <p class='font-weight-500'>
                  {{ instructor.classes_count }}
                  clases
                </p>
              </div>
            </div>

            <div class='carousel-instructor-mobile'>
              <carousel
                :navigation-enabled='true'
                :per-page='1'
                :per-page-custom='[
                  [423, 2],
                  [650, 3],
                  [1000, 4],
                  [1200, 5],
                  [1600, 7],
                ]'
                :speed='500'
                navigation-next-label="<i class='fas fa-chevron-right'></i>"
                navigation-prev-label="<i class='fas fa-chevron-left'></i>"
              >
                <slide
                  v-for='{
                    id,
                    avatar,
                    firstname,
                    lastname,
                    classes_count
                  } in getPublicInstructors'
                  :key='id'
                  class='d-flex flex-column flex-card-display flex-carousel-instructors'
                  style='flex-basis: inherit;'
                >
                  <router-link
                    :to='`/instructores/instructor/${id}/${firstname.toLowerCase()}-${lastname.toLowerCase()}`'
                    style='text-decoration: none'
                  >
                    <div
                      :style="{
                        backgroundImage: `url('${
                          avatar && avatar.url
                            ? avatar.url
                            : '@/assets/images/logo/default-img-instructors.png'
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }"
                      class='text-center mx-auto image-instructor-carousel'
                    ></div>
                    <h4 class='text-uppercase'>
                      {{ firstname }} {{ lastname }}
                    </h4>
                  </router-link>
                  <p class='font-weight-500'>
                    {{ classes_count }} clases
                  </p>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='view-home__part6' style='display: none'>
      <div class='container-fluid size'>
        <div class='row' style='width: 106%'>
          <div class='col-12'>
            <h2 class='pb-3'>
              APRENDE A MAQUILLARTE CON TUS INFLUENCERS FAVORITOS
            </h2>
          </div>
          <div class='col-12 mt-3'>
            <!-- ****** INICIO CARRUSEL PARTNERS ****** -->
            <carousel
              :navigation-enabled='true'
              :per-page='1'
              :per-page-custom='[
                [400, 2],
                [767, 3],
                [1200, 4],
              ]'
              :speed='500'
              class='mobile-carousels ml-0'
              navigation-next-label="<i class='fas fa-chevron-right'></i>"
              navigation-prev-label="<i class='fas fa-chevron-left'></i>"
            >
              <slide
                v-for='{ id, name, avatar } in allPartners'
                :key='id'
                class='justify-content-center ml-0'
              >
                <router-link :to="'/socio/' + id" style='text-decoration: none'>
                  <div class='text-header-img ml-0'>
                    <img
                      v-if="!avatar.includes('storage/avatar/default.png')"
                      :src='avatar'
                      alt=''
                      class='borderless size-img-partner'
                    />
                    <img
                      v-else
                      alt=''
                      class='borderless size-img-partner'
                      src='@/assets/images/logo/default-img-instructors.png'
                    />

                    <div class='box'>
                      <div class='capa'>
                        <h3>{{ name }}</h3>
                      </div>
                    </div>
                  </div>
                </router-link>
              </slide>
            </carousel>
            <!-- ******FIN CARRUSEL PARTNERS ****** -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config/config';
import Vue from 'vue';
import axios from 'axios';
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);
import { Carousel, Slide } from 'vue-carousel';
import CardCourse from './Courses/components/CardCourse';
import CardClass from './Courses/components/CardClass';
import {
  FETCH_HOME_CLASSES,
  FETCH_PUBLIC_COURSES,
  FETCH_PUBLIC_INSTRUCTORS,
} from '../../store/actions/public';

export default {
  name: 'HomeMain',
  metaInfo: {
    title: 'Aprende a Maquillarte - Cursos de Maquillaje - Maquillate.com',
  },
  components: {
    Carousel,
    Slide,
    CardCourse,
    CardClass,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  data () {
    return {
      allPartners: [],
      perPage: 12,
    };
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getToken',
      'getPublicInstructors',
      'getPublicCourses',
      'getPublicCategories',
      'getHomeClasses',
    ]),
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.showNavbar = true;
      },
    },
  },
  mounted () {
    this.fetchPublicClasses();
    this.fetchPublicInstructors();
    this.fetchPublicCourses();
    this.getPartners();
  },
  methods: {
    async fetchPublicClasses (
      filter = {
        order_by: 'DESC',
        per_page: this.perPage,
      },
    ) {
      await this.$store.dispatch(FETCH_HOME_CLASSES, filter);
    },
    async fetchPublicInstructors (
      filter = {
        order_by: 'ASC_firstname',
      },
    ) {
      await this.$store.dispatch(FETCH_PUBLIC_INSTRUCTORS, filter);
    },
    async fetchPublicCourses (
      filter = {
        order_by: 'DESC',
        per_page: this.perPage,
      },
    ) {
      await this.$store.dispatch(FETCH_PUBLIC_COURSES, filter);
    },

    async getPartners () {
      await axios.get(config.data.apiUrl + '/partners').then((resp) => {
        this.allPartners = resp.data.partners;
      });
    },
    getClassesCount (categories) {
      return categories.reduce((acc, curr) => {
        return acc + curr.classes_count;
      }, 0);
    },
    getCourseVideosCountByCategory (publicCourses, categoryId) {
      const publicCourseByCategory = publicCourses.filter(
        ({ course_categories }) =>
          course_categories.find(
            ({ course_category }) => course_category.id === categoryId,
          ),
      );

      return publicCourseByCategory.reduce((acc, curr) => {
        return acc + curr.course_videos.length;
      }, 0);
    },

    search () {
      this.inputSearch = !this.inputSearch;
    },
    categories () {
      this.showCategories = !this.showCategories;
    },
    getClassByCategories (publicClasses, categoryId) {
      if (!categoryId) return [];
      return publicClasses.filter((_class) =>
        _class.categories.find((category) => category.id === categoryId),
      );
    },
    toggleNavbar () {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
<style lang='scss'>
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}

.navbar-mobile {
  &.navbar-Header {
    display: none;
    margin-top: -2px;
    height: 68px;
    width: 100vw;
    background: transparent;
    position: fixed;
    transform: translate3d(0, 0, 0);
    transition: 0.3s all ease-out;
    z-index: 7;

    a {
      font-family: inherit !important;
    }

    @media (max-width: 1000px) {
      display: block !important;
    }

    .view-home-maquillate__header {
      margin-top: 13px;
    }
  }
}

.navbar-Header {
  margin-top: -2px;
  height: 68px;
  width: 100vw;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
  z-index: 10;

  a {
    font-family: inherit !important;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  .view-home-maquillate__header {
    margin-top: 12px;
  }
}

.bg-transparent-header {
  background: transparent;
}

.bg-black {
  background: #000;
}

.navbar-Header.hidden-navbar-Header {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.1s all ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.size-img-partner {
  object-fit: cover !important;
  width: 100%;
}

.textCard {
  height: 14vh;
  overflow-y: auto;
}

.instructorCard {
  margin-top: 1rem;
}

.icon-box {
  /* background-color: yellow; */
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.size-icon {
  /* background-color: red; */
  display: flex;
  width: 32px;
  height: 32px !important;
  object-fit: contain;
}

.buttons-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-icons {
  padding-bottom: 7px !important;
  display: flex;
  height: 70px;
}

.boxTotal {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  height: 100%;
}

.view-home-maquillate__header button.instructorBtn {
  padding-bottom: 12px !important;
  text-align: start !important;
  width: max-content;
}

.btnAllCat {
  padding-left: 0px;
  padding-top: 0px;
}

.footer-links {
  p a {
    text-decoration: none !important;
  }

  @media (min-width: 412px) and (max-width: 450px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px !important;
    padding-bottom: 15px;
  }
}

.footer-box {
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.footer-box div {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.footer-elmnt {
  margin: 0 5px;
}

.view-home-tabs .md-tabs.md-alignment-fixed .md-tabs-navigation .md-button {
  border-radius: 6px;
  min-width: fit-content !important;
}

.button-contact {
  background-color: #b7227e;
  color: white;
  padding: 11px;
  width: 200px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-size: 15px;
  white-space: nowrap !important;
}

.button-view-all-instructors-home {
  background-color: #b7227e;
  color: white;
  padding: 11px;
  width: 200px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-size: 15px;
  white-space: nowrap !important;
}

.button-all-classes-home {
  background-color: #fff !important;
  color: #000;
  padding: 11px !important;
  width: 200px !important;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid transparent;
  line-height: 2;
  align-content: center;
  border-radius: 0.25rem;
  font-size: 15px;
  white-space: nowrap !important;
}

.button-view-all-instructors-home:hover {
  color: #fff;
}

.md-ripple {
  z-index: 0 !important;
}

.md-ripple:active {
  background-color: black !important;
}

.md-tab {
  width: 320px;
}

.view-home-tabs .md-content.md-tabs-content.md-theme-default {
  height: auto !important;
  padding: 0px !important;
}

.carousel-instructor-mobile {
  display: none;
}

.VueCarousel-dot:focus {
  outline: none !important;
}

.view-home-tabs
.md-tabs.md-theme-default
.md-tabs-navigation
.md-button.md-active {
  background-color: #000 !important;
}

.button-view-all-courses {
  background-color: #fff;
  color: #000;
  padding: 11px;
  width: 200px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  white-space: nowrap !important;
  font-size: 15px;
}

.button-view-all-courses:hover {
  color: #000;
  white-space: nowrap !important;
}

.button-all-classes-home:hover {
  border: 1px solid #fff !important;
}

@media (max-width: 768px) {
  .md-tab {
    width: 100% !important;
  }
  .sidebar-home-hand-left::after {
    content: '';
    background-image: url(/img/swipe-left.73dc7c53.gif);
    width: 40px;
    height: 40px;
    padding: 15px;
    background-size: contain;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    left: 0px;
    -webkit-filter: brightness(0) saturate(100%) invert(100%);
    filter: brightness(0) saturate(100%) invert(100%);
  }

  .view-home__part8__column-left h1,
  .view-home__part8__column-right h1 {
    font-size: 1rem !important;
  }

  .card-h-w {
    margin: auto !important;
  }

  .backg-img2 .md-tabs-navigation {
    margin: 0px 5px 0px 35px !important;
  }

  .VueCarousel-inner {
    flex-basis: 100% !important;
  }

  .card-course-width {
    width: 300px;
    flex-basis: 100% !important;
  }

  .VueCarousel-slide,
  .VueCarousel-inner {
    flex-basis: 100% !important;
  }
}

@media only screen and (max-width: 576px) and (min-width: 0px) {
  .courses-home-carousel .VueCarousel-inner {
    display: flex;
    flex-direction: row;
    backface-visibility: hidden;
    justify-content: left !important;
  }

  .align-courses-center {
    align-items: center !important;
    padding: 10px 20px !important;
  }
  .image-instructor-carousel {
    height: 200px;
    padding: 10px;
    width: 90%;
    min-width: 150px;
  }
  .VueCarousel-slide,
  .VueCarousel-inner {
    flex-basis: 100% !important;
  }
  .card-h-w {
    width: 100%;
    padding-right: 10px;
  }
  .card-course-width {
    flex-basis: 100% !important;
    width: 100%;
  }
  .carousel-instructor-mobile {
    display: block !important;
  }

  .carousel-instructor-desktop {
    display: none;
  }

  .view-home__part5 .cards-instructors .VueCarousel-navigation-next {
    margin-right: 60px !important;
  }

  .flex-carousel-instructors {
    flex-basis: 50% !important;
    width: auto;
  }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .align-courses-center {
    align-items: center !important;
  }
  .card-course-width {
    width: 300px;
    flex-basis: 50% !important;
    padding-right: 5px;
  }
  .backg-img2 .VueCarousel {
    margin-bottom: 1.5rem !important;
  }
  .VueCarousel-slide,
  .VueCarousel-inner {
    flex-basis: 50% !important;
  }

  .backg-img2 .md-tabs-navigation {
    margin: 0px 5px 0px 35px !important;
  }

  .backg-img2 .md-tab {
    padding: 0px !important;
  }

  .button-contact {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .sidebar-home-hand-left::after {
    content: '';
    background-image: url('../../../src/assets/images/logo/swipe-left.gif');
    width: 40px;
    height: 40px;
    padding: 15px;
    background-size: contain;
    position: absolute;
    top: 0;
    transform: translateY(-10%);
    left: 0px;
    filter: brightness(0) saturate(100%) invert(100%);
  }


}

.h-w-education-home-banner {
  height: 100%;
  width: auto;

}

.courses-home-carousel .VueCarousel-inner {
  display: flex;
  flex-direction: row;
  backface-visibility: hidden;
  justify-content: center;
}

.size-instructor {
  width: 90% !important;
}

.align-courses-center {
  align-items: end;
}

</style>
