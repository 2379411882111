var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid px-0 view-home-maquillate"},[_c('div',{staticClass:"details-covers"},[_c('div',{staticClass:"px-3 my-5 info-box1"},[_c('div',{staticClass:"view-home-header__info"},[_c('h1',{staticStyle:{"font-weight":"900"}},[_vm._v(" 1RA Y ÚNICA PLATAFORMA DE AUTOMAQUILLAJE ")]),_c('p',{staticClass:"mt-3 mb-4"},[_vm._v(" Vence obstáculos, luce bella y siéntete segura mediante el maquillaje ")]),_c('div',{staticClass:"row justify-content-center view-home-maquillate__header"},[_c('router-link',{attrs:{"to":"/explorar"}},[_c('button',{staticClass:"btn mr-3 mt-2 button-see-classes",staticStyle:{"font-weight":"bold"},attrs:{"type":"submit"}},[_vm._v(" VER CLASES ")])]),(!_vm.getToken)?_c('router-link',{attrs:{"to":"/register"}},[_c('button',{staticClass:"btn btn-two pulse-animation mr-3 mt-2",staticStyle:{"border":"1.2px solid #b7227e"},attrs:{"type":"submit"}},[_vm._v(" CREAR CUENTA GRATIS ")])]):_vm._e()],1)])]),_vm._m(0)])]),_c('div',{staticClass:"backg-img2 container-fluid text-center pt-5"},[_c('div',{staticClass:"container-fluid size px-0"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-12 view-home-tabs pl-0 view-tabs-mobile mx-0 pl-lg-3 pr-0"},[_c('span',{staticClass:"sidebar-home-hand-left"}),(_vm.getHomeClasses)?_c('md-tabs',{staticClass:"md-transparent",attrs:{"md-alignment":"fixed"}},[_c('md-tab',{staticClass:"px-0",attrs:{"md-label":"Todas las clases"}},[_c('carousel',{attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
                  [545, 2],
                  [825, 3],
                  [1200, 4],
                ],"scroll-per-page":true,"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.getHomeClasses.items),function(_class){return _c('slide',{key:_class.id,staticClass:"d-flex flex-column flex-card-display align-courses-center pl-2",staticStyle:{"flex-basis":"inherit"}},[_c('card-class',{staticClass:"card-course-width mx-auto mx-lg-1",attrs:{"data":_class}})],1)}),1)],1),_vm._l((this.getPublicCategories),function(category){return _c('md-tab',{key:category.id,attrs:{"id":category.title,"md-label":category.title}},[_c('carousel',{attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
                [545, 2],
                [825, 3],
                [1200, 4],
              ],"scroll-per-page":true,"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.getClassByCategories(
                    _vm.getHomeClasses.items,
                    category.id,
                  )),function(_class){return _c('slide',{key:_class.id,staticClass:"d-flex flex-column flex-card-display align-courses-center pl-2",staticStyle:{"flex-basis":"inherit"}},[_c('card-class',{staticClass:"card-course-width mx-auto mx-lg-1",attrs:{"data":_class}}),(
                      !_vm.getClassByCategories(_vm.getHomeClasses.items, category.id)
                        .length
                    )?_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"details-class-description",staticStyle:{"color":"white"}},[_vm._v(" NO SE HAN REGISTRADO CURSOS EN ESTA CATEGORIA ")])]):_vm._e()],1)}),1)],1)})],2):_vm._e()],1),_c('div',{staticClass:"col-12 text-center mx-0 px-0"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/explorar/clases"}},[_c('button',{staticClass:"button-view-all-courses my-4 text-center"},[_vm._v(" VER TODAS LAS CLASES ")])])],1)])])]),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"view-home__part4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-12 px-0 buttons-box"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/explorar"}},[_c('button',{staticClass:"btn btn-primary btn-icons",attrs:{"type":"submit"}},[_c('div',{staticClass:"icon-box"},[_c('img',{staticClass:"size-icon",attrs:{"alt":"img_general","src":"/img/theme/youtube.png"}})]),_c('div',{staticClass:"info-button pl-4 text-left boxTotal"},[_c('span',{staticClass:"info-title"},[_vm._v("Total")]),_c('span',{staticClass:"class-info"},[_vm._v(_vm._s(_vm.getHomeClasses.total)+" Clases")])])])]),_vm._l((_vm.getPublicCategories),function(category){return _c('div',{key:category.id},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/explorar/clases/categoria/${category.slug}`}},[_c('button',{staticClass:"btn btn-primary btn-icons",attrs:{"type":"submit"}},[_c('div',{staticClass:"icon-box"},[_c('img',{staticClass:"size-icon",attrs:{"src":category.course_category_icon_file &&
                      category.course_category_icon_file.url
                        ? category.course_category_icon_file.url
                        : `img/theme/youtube.png`,"alt":""}})]),_c('div',{staticClass:"info-button pl-4 text-left boxTotal"},[_c('span',{staticClass:"info-title"},[_vm._v(_vm._s(category.title))]),_c('span',{staticClass:"class-info"},[_vm._v(_vm._s(category.classes_count)+" Clases")])])])])],1)})],2),_c('div',{staticClass:"col-12"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/explorar/clases"}},[_c('button',{staticClass:"button-all-classes-home text-center"},[_vm._v(" VER TODAS LAS CLASES ")])])],1)])])]),_c('div',{staticClass:"container-fluid view-home__part3",staticStyle:{"background":"white"}},[_c('div',{staticClass:"container size"},[_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 view-home__part3__column-left pt-0"},[_c('p',{staticClass:"title-h2 pt-4"},[_vm._v("¡ESTAMOS PARA SERVIRTE!")]),_c('p',[_vm._v(" Recibe asistencia en menos de 24 horas de nuestro equipo en Maquillate.com para resolver o responder cualquier pregunta que tengas. Servirte es nuestra misión. ")]),_c('router-link',{staticClass:"text-center text-lg-left mx-0 px-0",staticStyle:{"text-decoration":"none"},attrs:{"to":"/contactanos"}},[_c('button',{staticClass:"button-contact my-4"},[_vm._v("Contáctanos")])])],1)])])]),_c('div',{staticClass:"backg-img2 text-center pt-5"},[_c('div',{staticClass:"container-fluid size px-0"},[_c('div',{staticClass:"row"},[_vm._m(7),_c('div',{staticClass:"col-12 view-home-tabs view-tabs-mobile courses-payments mt-0 pt-0 mx-0 pl-lg-3 pr-lg-0"},[(_vm.getPublicCourses)?_c('md-tabs',{staticClass:"md-transparent",attrs:{"md-alignment":"fixed"}},[_c('md-tab',{staticClass:"px-2"},[_c('carousel',{class:{'courses-home-carousel': _vm.getPublicCourses.items.length < 4},attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
                  [545, 2],
                  [825, 3],
                  [1200, 4],
                ],"scroll-per-page":true,"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.getPublicCourses.items),function(course){return _c('slide',{key:course.id,staticClass:"d-flex flex-column flex-card-display align-courses-center",staticStyle:{"flex-basis":"inherit","padding-left":"10px"}},[_c('card-course',{staticClass:"card-course-width mx-auto mx-lg-1",attrs:{"data":course}})],1)}),1)],1)],1):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/explorar/cursos"}},[_c('button',{staticClass:"button-all-classes-home text-center my-4 mx-0 px-0"},[_vm._v(" VER TODOS LOS CURSOS ")])])],1)])])]),_c('div',{staticClass:"view-home__part5"},[_c('div',{staticClass:"container-fluid size-instructor"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-5"},[_c('h2',{staticClass:"pb-3"},[_vm._v(" CLASES IMPARTIDAS POR MAQUILLISTAS PROFESIONALES ")]),_c('p',[_vm._v(" Los instructores de Maquíllate.com, son iconos y expertos de la industria de maquillaje, emocionadas de compartir su experiencia, sabiduría y herramientas contigo. ")]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/instructores"}},[_c('button',{staticClass:"btn button-view-all-instructors-home my-4"},[_vm._v(" VER INSTRUCTORES ")])])],1),_c('div',{staticClass:"col-12 mt-4 cards-instructors text-center align-content-center align-items-center pr-2 pl-2"},[_c('div',{staticClass:"row mx-auto text-center carousel-instructor-desktop"},_vm._l((this.getPublicInstructors),function(instructor){return _c('div',{key:instructor.id,staticClass:"col-12 col-lg-3 align-items-center mx-auto mb-4"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/instructores/instructor/${
                  instructor.id
                }/${instructor.firstname.toLowerCase()}-${instructor.lastname.toLowerCase()}`}},[_c('div',{staticClass:"text-center mx-auto instructor-carousel-image",style:({
                    backgroundImage: `url('${
                      instructor.avatar && instructor.avatar.url
                        ? instructor.avatar.url
                        : '@/assets/images/logo/default-img-instructors.png'
                    }')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '240px',
                    maxHeight: '240px',
                    width: '240px',
                  })}),_c('h4',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(instructor.firstname)+" "+_vm._s(instructor.lastname)+" ")])]),_c('p',{staticClass:"font-weight-500"},[_vm._v(" "+_vm._s(instructor.classes_count)+" clases ")])],1)}),0),_c('div',{staticClass:"carousel-instructor-mobile"},[_c('carousel',{attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
                [423, 2],
                [650, 3],
                [1000, 4],
                [1200, 5],
                [1600, 7],
              ],"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.getPublicInstructors),function({
                  id,
                  avatar,
                  firstname,
                  lastname,
                  classes_count
                }){return _c('slide',{key:id,staticClass:"d-flex flex-column flex-card-display flex-carousel-instructors",staticStyle:{"flex-basis":"inherit"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/instructores/instructor/${id}/${firstname.toLowerCase()}-${lastname.toLowerCase()}`}},[_c('div',{staticClass:"text-center mx-auto image-instructor-carousel",style:({
                      backgroundImage: `url('${
                        avatar && avatar.url
                          ? avatar.url
                          : '@/assets/images/logo/default-img-instructors.png'
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    })}),_c('h4',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname)+" ")])]),_c('p',{staticClass:"font-weight-500"},[_vm._v(" "+_vm._s(classes_count)+" clases ")])],1)}),1)],1)])])])]),_c('div',{staticClass:"view-home__part6",staticStyle:{"display":"none"}},[_c('div',{staticClass:"container-fluid size"},[_c('div',{staticClass:"row",staticStyle:{"width":"106%"}},[_vm._m(8),_c('div',{staticClass:"col-12 mt-3"},[_c('carousel',{staticClass:"mobile-carousels ml-0",attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
              [400, 2],
              [767, 3],
              [1200, 4],
            ],"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.allPartners),function({ id, name, avatar }){return _c('slide',{key:id,staticClass:"justify-content-center ml-0"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/socio/' + id}},[_c('div',{staticClass:"text-header-img ml-0"},[(!avatar.includes('storage/avatar/default.png'))?_c('img',{staticClass:"borderless size-img-partner",attrs:{"src":avatar,"alt":""}}):_c('img',{staticClass:"borderless size-img-partner",attrs:{"alt":"","src":require("@/assets/images/logo/default-img-instructors.png")}}),_c('div',{staticClass:"box"},[_c('div',{staticClass:"capa"},[_c('h3',[_vm._v(_vm._s(name))])])])])])],1)}),1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"height-box"},[_c('img',{staticClass:"img-box1",attrs:{"src":"https://devmaquillate.b-cdn.net/home/img.webp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('p',{staticClass:"title-h2 mb-4"},[_vm._v(" MÁS DE 100 CLASES PARA APRENDER EN DISTINTAS CATEGORÍAS ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid view-home__part3",staticStyle:{"background":"white"}},[_c('div',{staticClass:"container size"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 view-home__part3__column-left pt-0 pb-4"},[_c('p',{staticClass:"title-h2"},[_vm._v("EDUCACIÓN EN LA PUNTA DE TUS DEDOS")]),_c('p',[_vm._v(" Aprende de cualquier parte del mundo, sin horarios y a tu ritmo. Controla tu tiempo y continúa educandote cuando quieras. ")])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-0 view-home__part3__column-right"},[_c('img',{staticClass:"h-w-education-home-banner",attrs:{"src":"https://devmaquillate.b-cdn.net/home/educacion.png","alt":"educacion"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid view-home__part8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 view-home__part8__column-left"},[_c('h1',[_vm._v("AUTOMAQUÍLLATE")])]),_c('div',{staticClass:"col-6 view-home__part8__column-right"},[_c('h1',[_vm._v("COMO UNA PRO")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid view-home__part3",staticStyle:{"background":"white"}},[_c('div',{staticClass:"container size"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-0 view-home__part3__column-right"},[_c('img',{staticClass:"h-w-education-home-banner",attrs:{"src":"https://devmaquillate.b-cdn.net/home/comunidad.png","alt":"comunidad"}})]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 view-home__part3__column-left"},[_c('p',{staticClass:"title-h2"},[_vm._v("ÚNETE A UNA COMUNIDAD GLOBAL")]),_c('p',[_vm._v(" Miles de personas de todo el mundo aprenden día a día sobre el mundo del Maquillaje. ¡Únete hoy! ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('p',{staticClass:"pb-3 title-h2"},[_vm._v("¡ENCUENTRA LO QUE TE GUSTA!")]),_c('p',[_vm._v(" Ya sea que seas un principiante o quieras intensificar tu aprendizaje, obtén la experiencia completa en casa con cientos de clases para todo los estilos y gustos. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-0 view-home__part3__column-right pl-0 pr-4"},[_c('img',{staticClass:"h-w-education-home-banner",attrs:{"src":"https://devmaquillate.b-cdn.net/home/servirte-section.png","alt":"servirte"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-0 pb-0"},[_c('p',{staticClass:"title-h2 text-center"},[_vm._v(" CURSOS PARA AGILIZAR TU EDUCACIÓN ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"pb-3"},[_vm._v(" APRENDE A MAQUILLARTE CON TUS INFLUENCERS FAVORITOS ")])])
}]

export { render, staticRenderFns }